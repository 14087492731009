<script>
export default {
	metaInfo: {
		title: 'Medios de pago',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<Container class="pa-0 pb-10">
		<v-row>
			<v-col cols="12">
				<div>
					<div class="mb-5">
						<h1 class="text-h5">MEDIOS DE PAGO</h1>
					</div>
					<div>
						<div class="mb-5">
							Contamos con la seguridad de una plataforma de pagos con una amplia trayectoria como lo
							es Mercado Pago. Las opciones de pago son:
						</div>
						<div class="mb-4">
							Efectivo a través de RapiPago, Link y Provincia Net. La acreditación de este tipo de pago
							demora entre 24 y 72 horas hábiles.
						</div>
						<div class="mb-4">
							Tarjetas de crédito Visa, MasterCard, Argencard, American Express, Cabal, Tarjeta
							Shopping, Tarjeta Naranja, Cencosud y Nativa. La acreditación de este tipo de pago es
							inmediata. En el siguiente link podrás ver los medios de pago actualizados:
							<a
								href="https://www.mercadopago.com.ar/ayuda/medios-de-pago-cuotas-promociones_264"
								target="_blank"
								>https://www.mercadopago.com.ar/ayuda/medios-de-pago-cuotas-promociones_264</a
							>
						</div>
						<div class="mb-4">
							En el caso de retirar tu compra en el local se podrá abonar en efectivo o tarjeta de
							crédito (no contará con los beneficios de MercadoPago)
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
