<script>
export default {
	metaInfo: {
		title: 'Términos y condiciones',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<Container class="pa-0 mb-10">
		<v-row>
			<v-col cols="12">
				<div>
					<div class="mb-5">
						<h1 class="text-h5">TÉRMINOS Y CONDICIONES</h1>
					</div>
					<div class="mb-4">
						1. En la página web de Tiendas Selecciones www.tiendaselecciones.com.ar se ofrecen productos
						al consumidor final
					</div>

					<div class="mb-4">
						<div>
							2. La página web
							<a href="https://www.tiendaselecciones.com.ar">www.tiendaselecciones.com.ar</a> es
							gestionada por:
						</div>

						<div>One Minetta Media S.A.</div>

						<div>Holmberg 4156</div>

						<div>1430DQF, Saavedra</div>

						<div>Capital Federal</div>

						<div>Argentina</div>

						<div>
							E-mail:
							<a href="mailto:tienda.selecciones@oneminetta.com">tienda.selecciones@oneminetta.com</a>
						</div>
						<div>Teléfono: 4548-0623 (Lunes a jueves de 9 a 16 hs y viernes de 9 a 14 hs)</div>
					</div>

					<div class="mb-4">
						3. Ya sea mediante confirmación del correspondiente campo en el proceso de pedido, o bien si
						Usted hace uso efectivo del servicio, quedarán aceptadas las condiciones de uso de Tienda
						Selecciones. En tal caso, Usted reconoce que nosotros –One Minetta Media S.A.- consideremos
						el uso de nuestro servicio como reconocimiento de las condiciones de uso. Estas condiciones
						de uso podrán ser consultadas, impresas o grabadas localmente en cualquier momento bajo
						Términos y condiciones
					</div>

					<div class="mb-4">
						4. Los productos solo están disponibles para personas que tengan capacidad legal para
						contratar. No podrán adquirir los productos las personas que no tengan esa capacidad y los
						menores de edad. Los actos que éstos realicen en el Sitio serán responsabilidad de sus
						padres, tutores, encargados o curadores, y por tanto se considerarán realizados por éstos en
						ejercicio de la representación legal con la que cuentan.
					</div>

					<div class="mb-4">
						5. Para adquirir los productos ofrecidos en el Sitio los Usuarios Registrados deberán
						facilitar determinados datos de carácter personal. Su información personal se procesa y
						almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y
						protección tanto física como tecnológica. Para mayor información sobre la privacidad de los
						Datos Personales y casos en los que será revelada la información personal, se pueden
						consultar nuestras Políticas de Privacidad en cualquier momento bajo Políticas de Privacidad
					</div>

					<div class="mb-4">
						6. Todas las transacciones monetarias realizadas por los clientes en
						<a href="https://www.tiendaselecciones.com.ar">www.tiendaselecciones.com.ar</a> son
						manejados por One Minetta Media S.A
					</div>

					<div class="mb-4">
						7. Tienda Selecciones podrá adaptar estas condiciones de uso de vez en cuando, por ejemplo,
						para poner en práctica exigencias jurídicas o tener en cuenta modificaciones de
						funcionamiento. Vas a encontrar las condiciones de uso vigentes en cada momento bajo
						Términos y condiciones. En caso de que no estuviera de acuerdo con las modificaciones de
						nuestras condiciones de uso, lamentablemente ya no podrá hacer uso de nuestro servicio
					</div>

					<div class="mb-4">
						8. La duración de la venta de un producto está limitada temporalmente, estableciéndose de
						forma individual para cada producto por parte de Tienda Selecciones. Transcurrido el período
						de duración ya no es posible la compra.
					</div>

					<div class="mb-4">
						9. La venta del producto no se produce hasta que Tienda Selecciones no haya registrado con
						éxito el cobro. En caso de no tener éxito con el primer intento de cobro (por ej. porque no
						exista la cuenta indicada o no disponga de saldo suficiente, el número de la tarjeta de
						crédito sea erróneo o la misma esté al límite) tampoco se producirá la venta del producto.
					</div>

					<div class="mb-4">
						10. Tienda Selecciones se reserva el derecho de modificar en cualquier momento el alcance y
						las funciones de su página web, de limitarlas o cancelarlas. Aunque nos esforzamos por
						ofrecer nuestro servicio sin molestias técnicas, en particular, los trabajos de
						mantenimiento, la continuación del desarrollo y/o otras molestias pueden limitar y/o
						parcialmente interrumpir las posibilidades de uso. Bajo determinadas circunstancias se
						pueden producir aquí pérdidas de datos.Tienda Selecciones no asume por ello garantía alguna
						sobre la disponibilidad del servicio o la ausencia de molestias técnicas o pérdidas de
						datos.
					</div>

					<div class="mb-4">
						11. Tienda Selecciones se reserva todos los derechos de Propiedad intelectual y demás
						derechos sobre el servicio así como sobre los contenidos publicados, informaciones,
						imágenes, videos y bancos de datos (a continuación denominada "propiedad protegida"). Por
						ello, está expresamente prohibida cualquier modificación, reproducción, publicación,
						transmisión a terceros y/o demás uso o explotación de la propiedad protegida sin el
						consentimiento previo por escrito de Tienda Selecciones.
					</div>

					<div class="mb-4">
						12. No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente
						a interferir tanto en las actividades y operatoria de Tienda Selecciones como en las
						ofertas, descripciones, cuentas o bases de datos de Tienda Selecciones. Cualquier
						intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de
						propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán posible a
						su responsable de las acciones legales pertinentes, y a las sanciones previstas por este
						acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.
					</div>

					<div class="mb-4">
						13. En caso de que disposiciones concretas de estas Condiciones Generales de Contratación o
						del contrato concluido con la parte contratante fueran ineficaces en todo o en parte, ello
						no afectará a la eficacia del resto del contrato.
					</div>

					<div class="mb-4">
						14. Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República
						Argentina, las cuales podrán ser consultadas en el siguiente sitio: www.mecon.gov.ar.
						Cualquier controversia derivada del presente acuerdo, su existencia, validez,
						interpretación, alcance o cumplimiento, será sometida a los tribunales ordinarios de la
						Capital Federal.
					</div>

					<div class="mb-4">
						15. La compra se puede realizar solo con tarjeta de crédito en nuestro sitio
						<a href="https://www.tiendaselecciones.com.ar">www.tiendaselecciones.com.ar</a> y en
						efectivo en nuestro Showroom sito en Holmberg 4156 PB (1430DQF) CABA, Argentina.
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
