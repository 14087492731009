<script>
export default {
	metaInfo: {
		title: '¿Cómo comprar?',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<Container class="pa-0 mb-10">
		<v-row>
			<v-col cols="12">
				<div>
					<div class="mb-5">
						<h1 class="text-h5">¿Cómo comprar?</h1>
					</div>
					<div class="mb-4">
						1. Elegí el producto y la cantidad que quieras comprar. Hacé clic en el botón de "Añadir al
						carrito". Esto agregará el producto a tu carrito donde podrás continuar comprando o
						finalizarla haciendo clic en "Pagar".
					</div>

					<div class="mb-4">
						2. YAl hacer clic en "Pagar" te mostrará un Resumen de los productos y cantidades que
						estaban añadidos en Tu Carrito donde podrás modificar cantidades o quitar productos. Al
						darle SEGUIR debes INICIAR SESIÓN (CREANDO una nueva cuenta o REGISTRÁNDOTE por primera
						vez).
					</div>

					<div class="mb-4">
						3. Elegí el modo de Envío: Antes de pagar debes seleccionar el destino de entrega. Luego al
						hacer clic en "Pagar" deberás completar tus datos de envío y facturación donde deseas
						recibir el producto.
					</div>

					<div class="mb-4">
						4. Pagá con Mercado Pago o en efectivo en el local.
						<div class="mt-2 ml-2">
							Contamos con la seguridad de una plataforma de pagos con una amplia trayectoria como lo
							es Mercado Pago. Las opciones de pago son:
							<div class="ml-4 mt-2">
								Efectivo a través de RapiPago, Link y Provincia Net. La acreditación de este tipo de
								pago demora entre 24 y 72 horas hábiles.
							</div>
							<div class="ml-4 mt-2">
								Tarjetas de crédito Visa, MasterCard, Argencard, American Express, Cabal, Tarjeta
								Shopping, Tarjeta Naranja, Cencosud y Nativa. La acreditación de este tipo de pago es
								inmediata. En el siguiente link podrás ver los medios de pago actualizados:
								<a
									href="https://www.mercadopago.com.ar/ayuda/medios-de-pago-cuotas-promociones_264"
									target="_blank"
									>https://www.mercadopago.com.ar/ayuda/medios-de-pago-cuotas-promociones_264</a
								>
							</div>
							<div class="ml-4 mt-2">
								En el caso de retirar tu compra en el local se podrá abonar en efectivo o tarjeta de
								crédito (no contará con los beneficios de MercadoPago)
							</div>
						</div>
					</div>

					<div class="mb-4">
						5. Una vez acreditado el pago, haremos el envío o la entrega correspondiente de tu compra al
						domicilio informado.
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
