<script>
export default {
	metaInfo: {
		title: 'Políticas de privacidad',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<Container class="pa-0 pb-10">
		<v-row>
			<v-col cols="12">
				<div class="mb-5">
					<h1 class="text-h5">Políticas de protección de datos Personales</h1>
				</div>
				<p>
					<b>NUESTRO COMPROMISO CON LA PRIVACIDAD.</b>
				</p>
				<p>
					Bienvenido al Sitio Web (en adelante el “Sitio Web”) de One Minetta Media S.A. CUIT
					30-65642821-6, con domicilio en Holmberg 4156, Planta Baja de la Ciudad Autónoma de Buenos
					Aires (en adelante OMM).
				</p>
				<p>
					Utilizando el Sitio de Internet de OMM, el usuario (en adelante “Usuario” o “Usuarios”, según
					corresponda) acepta sin restricciones, reservas, ni modificaciones esta Políticas de Privacidad
					del Sitio web. Si Usted no acepta las condiciones de esta Política de Privacidad incluidos en
					el Sitio Web, por favor no use este sitio web ni divulgue su información personal.
				</p>
				<p>
					La Política de Privacidad podrá ser periódicamente modificada y actualizada por OMM sin
					notificación previa al Usuario ni a ninguna otra persona física o jurídica.
				</p>
				<p>
					El Usuario declara ser mayor de 18 años, poseer capacidad legal para contratar y no tener
					ningún impedimento para utilizar el Sitio Web según las leyes de la Argentina y/o de las
					jurisdicciones aplicables, y se obliga a utilizar el mismo para un propósito lícito. Si Usted
					no cumple con estos requisitos exigidos por favor abandone el Sitio Web.
				</p>
				<p><b>INFORMACIÓN PERSONAL.</b></p>
				<p>
					OMM respeta su derecho a la protección de los datos personales, los cuales están amparados por
					la Ley de Protección de Datos Personales N° 25.326 y normativa concordante.
				</p>
				<p>
					Todos los datos que el Usuario proporcione a OMM se encontrarán sujetos a nuestras Políticas de
					Privacidad. Los Datos se procesarán y almacenarán en servidores o medios magnéticos que
					mantienen altos estándares de seguridad y protección tanto física como tecnológica (en adelante
					“Base de Datos”). La Base de Datos ha sido debidamente registrada ante la Dirección Nacional de
					Protección de Datos Personales en los términos de la Ley 25.326, siendo el responsable de la
					misma One Minetta Media S.A., con domicilio en Holmberg 4156, Planta Baja, Capital Federal.
				</p>
				<p>
					El término “Información Personal” que se utiliza en esta Política de Privacidad y que se
					recolecta en el Sitio Web significa información que lo identifica a Usted personalmente, como
					su nombre y apellido, número de documento de identidad, número de teléfono y correo electrónico
					y, en algunos casos que el Usuario determine, domicilio, fecha de nacimiento y género. Dicha
					Información Personal resulta necesaria para poder utilizar esta página, concretar las
					transacciones en el Sitio Web, validar los pedidos, recibir pagos y mejorar la labor de
					información y comercialización de los productos ofrecidos, generar información sobre ventas,
					ofrecer promociones y descuentos y enviar información sobre nuevos productos y ofertas, ya sea
					mediante correo electrónico, mensaje de texto o notificaciones en el Sitio Web.
				</p>
				<p>
					También podremos utilizar la Información Personal para mejorar nuestros servicios de marketing,
					definir estrategias, para datos estadísticos y para mejorar el diseño del Sitio Web.
				</p>
				<p>
					No utilizaremos la Información Personal con otro fin distinto al expuesto precedentemente.
					Cuando Usted decide suministrar la Información Personal, Usted acepta y presta su
					consentimiento libre, expreso e informado para que OMM pueda usarla de acuerdo con esta
					Política de Privacidad y autoriza a que la misma sea tratada, almacenada, recopilada o cedida
					nacional y/o internacionalmente a terceros para su tratamiento, utilización para la
					comercialización y entrega de productos así como para aquellas actividades de soporte técnico y
					de mejora del Sitio Web.
				</p>
				<p>
					La responsable de la Base de Datos podrá ceder la Información Personal a cualquier subsidiaria
					y/o empresas afiliadas y/o agencias de publicidad y/o terceros que presten el servicio de
					hosting del Sitio Web y terceros relacionados con dicho proveedor así como podrá migrar la
					Información Personal a otro sitio web de su propiedad o administrado por ésta, siempre
					respetando la finalidad para la cual la Información Personal ha sido recolectada. En virtud de
					ello, aceptando esta Política de Privacidad Usted está consintiendo expresamente esta cesión.
				</p>
				<p><b>CORREO ELECTRÓNICO.</b></p>
				<p>
					La confidencialidad de los datos de la cuenta y contraseña del Usuario son de su exclusiva
					responsabilidad. Asimismo, todas las actividades realizadas en el Sitio Web bajo su nombre de
					Usuario y contraseña son de su exclusiva responsabilidad.
				</p>
				<p>
					OMM no se responsabiliza por la certeza de los Datos Personales provistos por los Usuarios. Los
					Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y
					autenticidad de los Datos Personales ingresados.
				</p>
				<p><b>SEGURIDAD.</b></p>
				<p>
					OMM cumple cabalmente con las disposiciones contenidas en la ley 25.326 y sus normas
					concordantes y adopta las medidas técnicas y organizativas necesarias para garantizar la
					seguridad y confidencialidad de sus datos personales. En ese sentido, OMM mantiene los datos de
					información personal identificable, proporcionada voluntariamente en una base de datos
					electrónica que se encuentra debidamente registrada.
				</p>
				<p>
					A pesar de que OMM hace todos los esfuerzos razonables por proteger la información recopilada
					por medio del Sitio Web, por favor tome en cuenta que siempre existe un riesgo implícito al
					suministrar datos por medio del Internet por lo que no se puede garantizar que el Sitio Web sea
					cien por ciento (100%) seguro contra la manipulación ilegal o la piratería.
				</p>
				<p><b>INFORMACIÓN DISPONIBLE EN EL SITIO.</b></p>
				<p>
					OMM no garantiza ni asegura la precisión, idoneidad, integridad o veracidad de cualquier tipo
					de información disponible en el Sitio Web. El Usuario asume todos los riesgos asociados con el
					uso de cualquier contenido, incluyendo veracidad, completud, integridad o utilidad del mismo.
				</p>
				<p><b>ENLACES A OTRAS PÁGINAS WEB.</b></p>
				<p>
					A utilizar el Sitio Web podrían existir enlaces a ciertos sitios web de terceros, pero esto no
					indica que sean propiedad de OMM. En virtud de ello, OMM no tiene control sobre tales sitios.
					Al proporcionar estos enlaces, OMM NO está endosando, adoptando, o accediendo al contenido de
					estos sitios enlazados o a los productos y servicios de estos terceros ajenos a OMM ni a sus
					políticas de privacidad. OMM NO revisa o controla de manera alguna el contenido de estos sitios
					de terceros y ese contenido no forma parte del Sitio Web.
				</p>
				<p>
					OMM niega expresamente cualquier responsabilidad por el contenido de cualquier sitio de
					terceros enlazado a nuestro Sitio Web o los productos y servicios de estos terceros y, en
					virtud de ello, no se responsabiliza, ni responderá de manera alguna por los daños y
					perjuicios, ya sean actuales o eventuales, materiales o morales, directos o indirectos, que
					sufrieren los Usuarios y que se derivaran de la información contenida en tales sitios de
					terceros o de la relación que los usuarios pudieren entablar con tales terceros.
				</p>
				<p>
					Atento lo expuesto, se ruega que verifique la Política de Privacidad así como los Términos y
					Condiciones de los sitios de terceros a los que acceda a través de un enlace en nuestro Sitio
					Web
				</p>
				<p><b>UTILIZACION DEL SITIO.</b></p>
				<p>
					El Usuario reconoce que OMM tiene la potestad exclusiva de establecer los límites y condiciones
					para la utilización del SITIO.
				</p>
				<p>
					a) Control de contenidos. El Usuario reconoce a OMM plena y absoluta discreción para
					preseleccionar, rechazar, remover y/o modificar cualquier contenido en el Sitio Web. Asimismo,
					no será responsable frente al Usuario ni frente ningún tercero por los daños, infracciones,
					delitos, contravenciones o cualquier otra clase de ilícitos que pudieran cometerse, ya sea
					trate de derechos de propiedad intelectual, al honor y reputación de las personas, sus datos
					personales, crediticios, derechos del consumidor, ni cualquier otro derecho que pudiera ser
					vulnerado como consecuencias del mal uso del Sitio Web por parte de los Usuarios.
				</p>
				<p>
					b) Acceso a la información. El Usuario acepta que OMM, a su propia discreción, puede acceder,
					conservar, revelar y eliminar la información de su cuenta y todo contenido que se encuentre en
					el Sitio Web.
				</p>
				<p>
					c) Cancelación. El Usuario acepta que OMM podrá cancelar, eliminar o bloquear su registro en el
					Sitio Web sin justificación ni previo aviso y que ello no le dará al Usuario derecho a reclamar
					ninguna indemnización.
				</p>
				<p>
					d) Terminación. El Usuario acepta que OMM se reserva el derecho de modificar de cualquier
					manera, discontinuar, interrumpir, cancelar y/o terminar el servicio del Sitio Web sin ninguna
					justificación ni previo aviso, bajo su única discreción y que ello no le dará al Usuario
					derecho a reclamar ninguna indemnización.
				</p>
				<p><b>OTRA INFORMACIÓN. COOKIES.</b></p>
				<p>
					OMM recopila información sobre el uso de nuestro Sitio Web a través de cookies y tecnologías
					similares. Las “cokies” son pequeños archivos o cantidades de información que se almacenan en
					su navegador, en el disco rígido de su computadora cuando Usted visita una página web, de
					manera que el sitio web puede consultar la actividad previa del navegador en ese sitio web. Una
					"cookie" no recoge ni guarda su nombre u otra información de identificación personal, pero
					cuando regrese a nuestro Sitio Web, la cookie nos permite brindarle el contenido y las
					características que puedan ser de mayor interés para Usted, basado en sus actividades
					anteriores en nuestro sitio.
				</p>
				<p>
					Su navegador Web se puede configurar para que Usted pueda controlar si acepta o rechaza cookies
					o para que ser notificado cada vez que se le envía una cookie. Si su navegador está configurado
					para rechazar las cookies, los sitios como el nuestro, que tienen activadas las cookies, no lo
					reconocerán cuando regrese al sitio y parte de la funcionalidad de la página se podrá perder.
				</p>
				<p>
					Por favor tenga en cuenta que los posibles sitios vinculados y que no pertenecen a OMM también
					pueden utilizar cookies. OMM no puede controlar el uso de cookies de estos sitios.
				</p>
				<p><b>PRIVACIDAD DE MENORES.</b></p>
				<p>
					Nuestro Sitio Web está orientado hacia la audiencia adulta y no está diseñado para, o con la
					intención de, atraer a menores de 18 años. OMM no recopila datos personales de ninguna persona
					que sepamos sea menor de 18 años de edad.
				</p>
				<p>
					Si bien quienes no tengan capacidad para contratar deben abstenerse de efectuar compras a
					través del Sitio Web, en caso de hacerlo, se entiende que lo realizan a través de sus padres o
					tutores. Los padres, tutores o responsables de los menores de edad o incapaces que utilicen el
					Sitio Web serán responsables por dicho uso, incluyendo cualquier cargo, facturación o daño que
					se derive de él, y se entiende que aceptan la presente Política de Privacidad.
				</p>
				<p><b>CÓMO CONTACTARNOS.</b></p>
				<p>
					Si tuviera alguna duda o inquietud acerca de nuestra Política de Privacidad o de la recolección
					de datos, por favor envíenos un mensaje de correo electrónico:
					atencion_al_cliente@oneminetta.com.
				</p>
				<p><b>PROTECCION DE DASTOS PERSONALES.</b></p>
				<p>
					La Ley de Protección de los Datos Personales (Ley N° 25.326) es una norma de orden público que
					regula la actividad de las bases de datos que registran información de carácter personal. Su
					objeto es garantizar a las personas el control del uso de sus datos personales.
				</p>
				<p>
					El Usuario, en su carácter de titular de la Información Personal podrá, en cualquier momento,
					solicitar la actualización, rectificación en caso de errores o el retiro, bloqueo, total o
					parcial de los datos existentes en el banco de datos, enviando un correo electrónico a
					atencion_al_cliente@oneminetta.com, con el asunto: REMOCION.
				</p>
				<p>
					Asimismo el Usuario, en su carácter de titular de la Información Personal, tiene la facultad de
					ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis
					(6) meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el
					artículo 14, inciso 3 de la Ley N° 25.326.
				</p>
				<p>
					Se hace saber que el artículo 27, inciso 3 de la Ley 25.326 establece que “el titular podrá en
					cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que
					se refiere el presente artículo”. Asimismo, el párrafo tercero del artículo 27 del Anexo I del
					Decreto 1558/01 establece que “en toda comunicación con fines de publicidad que se realice por
					correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá
					indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el
					retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado se
					deberá informar el nombre del responsable o usuario del banco de datos que proveyó la
					información”. Es posible que, de tanto en tanto, pongamos la citada base de datos a disposición
					de organizaciones cuyos productos o servicios, en nuestra opinión, podrían resultar de su
					interés. Si prefiere que no compartamos con dichas organizaciones la información referente a su
					persona, envíe un mensaje comunicándonos su decisión a: atencion_al_cliente@oneminetta.com o
					por correo a la dirección de OMM indicada en el encabezado de estas políticas.
				</p>
				<p>
					La Agencia de Acceso a la Información Pública, Órgano de Control de la Ley Nº 25.326, tiene la
					atribución de atender las consultas, denuncias y reclamos que se interpongan con relación al
					incumplimiento de las normas sobre protección de datos personales. Cualquier persona usuaria
					que tenga dudas o un problema con esta Política de Privacidad podrá recurrir ante dicho
					organismo o través del portal: https://www.argentina.gob.ar/aaip/datospersonales.
				</p>
				<p><b>ACTUALIZACIÓN DE LA POLÍTICA DE PRIVACIDAD.</b></p>
				<p>Cualquier cambio a la Política de Privacidad del Sitio Web será anunciado en esta página.</p>
				<p>Esta Política de Privacidad fue actualizada por última vez el 27/10/2021.</p>
			</v-col>
		</v-row>
	</Container>
</template>
