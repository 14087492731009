<script>
export default {
	metaInfo: {
		title: '¿Quiénes somos?',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<Container class="pa-0 pb-10">
		<v-row>
			<v-col cols="12">
				<div>
					<div class="mb-5">
						<h1 class="text-h5">¿Quiénes somos?</h1>
					</div>
					<div>
						<div class="mb-5">
							Tienda Selecciones te da respuestas. No es simplemente un canal de venta sino un espacio
							que te ofrece las herramientas para contribuir con tu cuidado personal y el de tus seres
							queridos. Desde libros de salud y cocina, especieros, jabones, hasta otros productos
							decorativos, a través de nuestra tienda intentamos brindar una solución para tus
							necesidades cotidianas. ¿Necesitás cocinar rápido y simple? Elegí Cocina en 30 minutos.
							¿Querés aprender a cocinar una verdadera paella? Cocina del mundo. ¿Te gusta leer
							novelas? Hay más de 50 opciones ¿Buscás darle un aroma especial a tu hogar? No te pierdas
							la colección de jabones aromáticos que tenemos para vos, y hay mucho más.
						</div>
						<div class="mb-5">
							Todos y cada uno de los productos exhibidos en la tienda Selecciones fueron pensados
							especialmente para vos y tu bienestar. Además, tienen precios muy accesibles y amplias
							posibilidades de pago y envío a todo el país. Tienda Selecciones no es una tienda más, es
							un espacio pensado para vos.
						</div>
						<div class="mb-5">
							La tienda Selecciones cuenta con el aval de una marca mundialmente conocida como
							Selecciones de Reader´s Digest que hace 80 años ofrece a los clientes de la Argentina y
							el Cono Sur productos y servicios de calidad comprobada.
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
