<script>
export default {
	metaInfo: {
		title: 'Envíos y Devoluciones',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<Container class="pa-0 mb-10">
		<v-row>
			<v-col cols="12">
				<div>
					<div class="mb-8">
						<h1 class="text-h5">Condiciones de Envío y Devolución</h1>
					</div>
					<div>
						<div class="mb-5">
							<h3 class="text-h6">ENVIOS</h3>
						</div>
						<div class="mb-4">
							Realizamos envíos a todo el país a través de CORREO ARGENTINO. El tiempo estimado de
							entrega dentro de CABA, GBA y Resto del País es de 6 días hábiles desde la acreditación
							del pago.
						</div>
						<div class="mb-4">
							Los envíos por correo se hacen al domicilio indicado por el cliente. También se pueden
							retirar por el local en Holmberg 4156 PB (1430DQF), Saavedra, Capital Federal de lunes a
							jueves de 9 a 16 hs y Viernes de 9 a 14 hs.
						</div>
						<div class="mb-4">
							Las opciones de envío se pueden elegir avanzando en la orden de compra, luego de iniciar
							tu sesión y completar el domicilio.
						</div>
						<div class="mt-6">
							<div class="mb-4">
								<b>¿Dónde y cómo puedo hacer el seguimiento de mi compra? </b> Una vez despachada tu
								compra recibirás un correo electrónico con el código de seguimiento de tu envío, con
								el que podrás revisar el estado de tu encomienda en la web de CORREO ARGENTINO.
								También podés escribirnos a
								<a href="mailto:tienda.selecciones@oneminetta.com"
									>tienda.selecciones@oneminetta.com</a
								>
								o llamarnos al 4548-0623.
							</div>
							<div class="mb-4">
								<b>¿Puedo retirar mi compra por algún lugar?</b> Sí, podés retirar tu producto por
								nuestro local de ventas de Holmberg 4156 PB (1430DQF), Saavedra, Capital Federal de
								lunes a viernes de 10 a 17 hs.
							</div>
							<div class="mb-4">
								<b>¿Cuál es el costo de envío</b> El costo de envío será informado una vez
								seleccionados los productos y dirección de entrega.
							</div>
							<div>
								<b>¿Mi pedido puede ser recibido por otra persona?</b> Una persona de su confianza
								podrá recibir su paquete siempre y cuando acredite su identidad al momento de la
								recepción. Además, deberá tener en su posesión el documento de Identidad del comprador
								o una fotocopia de éste. El correo hará dos intentos de entrega. En el caso de que
								nadie lo reciba en esas dos oportunidades, podrá retirarlo de la sucursal de CORREO
								ARGENTINO que informe el aviso de visita, para ello es necesario que se presente con
								su documento si es el titular de la compra o una carta de autorización junto a una
								fotocopia del documento del titular si no es el comprador.
							</div>
						</div>
					</div>
					<div>
						<div class="mb-5 mt-8">
							<h3 class="text-h6">POLÍTICA DE DEVOLUCIONES</h3>
						</div>
						<div>
							Si el producto que recibiste tiene alguna falla o no corresponde con el producto que
							seleccionaste, por favor comunicate con nosotros dentro de los 10 días hábiles de
							recibida la compra. Se podrá cambiar por otro artículo o te devolvemos el dinero. La
							devolución deberá hacerse dentro de los 10 días hábiles a partir de la entrega del
							producto. Se tomará el valor al que pagaste el producto al momento de comprarlo,
							independientemente que al momento de la devolución tenga otro valor. El crédito en tienda
							o reintegro por tarjeta de crédito, se emitirá una vez recibido el producto y constatado
							que se encuentre en condiciones. En este caso, todos los gastos involucrados en el
							proceso correrán por cuenta de Tienda Selecciones.
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
